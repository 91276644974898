<template>
    <a-layout style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style=";background-color:#fff;width:calc(60%);margin-left:auto;margin-right:auto; padding-top:20px; min-width:fit-content;min-height:calc(100% - 48px)">
                <a-layout style="height:100%;background:#fff">
                    <a-layout-content class="cardMain">
                        <div class="cardFrom">
                            <a-form-model ref="ruleForm" :model="formModel" :rules="rules">

                                <a-form-item label="卡券图片" style="margin-bottom:0px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">

                                    <a-upload name="image"
                                              :data="ParamData"
                                              list-type="picture-card"
                                              class="avatar-uploader"
                                              action="/FileServer/Upload?secpath=cardvoucher"
                                              :remove="removeImg"
                                              :fileList="fileList"
                                              @change="imgChange" style="">
                                        <div v-if="fileList.length < 1">
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">
                                                上传图片
                                            </div>
                                        </div>
                                    </a-upload>
                                </a-form-item>

                                <a-form-item label="名称" style="margin-bottom:0px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <a-form-model-item has-feedback prop="name">
                                        <a-input placeholder="奖励名称" type="text" style="width:700px" v-model="formModel.name"></a-input>
                                    </a-form-model-item>
                                </a-form-item>

                                <a-form-item label="活动日期" style="margin-bottom:0px;"
                                             prop="beginTime"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">

                                    <a-form-model-item prop="beginTime" style="margin-left:30px;">
                                        <a-date-picker v-model="formModel.beginTime"
                                                       show-time
                                                       type="date"
                                                       :disabled-date="disabledStartDate"
                                                       format="YYYY-MM-DD HH:mm:ss"
                                                       placeholder="开始时间"
                                                       style="width:100px"
                                                       @openChange="handleStartOpenChange" />
                                    </a-form-model-item>

                                    <a-form-model-item prop="endTime" style="margin-top:-65px;margin-left:270px">
                                        <a-date-picker show-time
                                                       style="width:100px;"
                                                       type="date"
                                                       :disabled-date="disabledEndDate"
                                                       format="YYYY-MM-DD HH:mm:ss"
                                                       placeholder="失效时间"
                                                       :open="endOpen"
                                                       @openChange="handleEndOpenChange"
                                                       v-model="formModel.endTime" />
                                    </a-form-model-item>
                                </a-form-item>

                                <a-form-item label="有效期" style="margin-bottom:00px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <a-form-model-item has-feedback prop="linkman">
                                        <a-input-number :disabled="!!formModel.Status" placeholder="天数" v-model="formModel.effectday" :min="1" style="width: 700px"></a-input-number>
                                    </a-form-model-item>
                                </a-form-item>

                                <a-form-item label="数量" style="margin-bottom:00px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <a-form-model-item has-feedback prop="linkman">
                                        <a-input-number :disabled="!!formModel.Status" placeholder="数量" v-model="formModel.count" :min="1" style="width: 700px"></a-input-number>
                                    </a-form-model-item>
                                </a-form-item>

                                <a-form-item label="价值" style="margin-bottom:0px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <a-form-model-item has-feedback prop="linkman">
                                        <a-input-number :disabled="!!formModel.Status" placeholder="金额" v-model="formModel.cash" :min="0" style="width: 700px"></a-input-number>
                                    </a-form-model-item>
                                </a-form-item>

                                <a-form-item label="价格" style="margin-bottom:0px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <a-form-model-item has-feedback prop="linkman">
                                        <a-input-number :disabled="!!formModel.Status" placeholder="金额" v-model="formModel.price" :min="0" style="width: 700px"></a-input-number>
                                    </a-form-model-item>
                                </a-form-item>
                                <a-form-item label="更换样式" style="margin-bottom:0px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <color-picker v-model="formModel.background"></color-picker>
                                </a-form-item>

                                <a-form-item label="领取模式" style="margin-bottom:0px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <a-form-model-item has-feedback prop="linkman">
                                        <a-select :disabled="!!formModel.Status" v-model="formModel.getmodel" style="width: 700px">
                                            <a-select-option :value="0" selected="selected">单次领取</a-select-option>
                                            <a-select-option :value="1">重复领取</a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </a-form-item>

                                <a-form-item label="使用规则" style="margin-bottom:0px;"
                                             :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol">
                                    <Rich v-bind:value.sync="formModel.description" style="width:100%;"></Rich>
                                </a-form-item>

                            </a-form-model>
                        </div>
                        <div class="cradView">
                            <div class="cradTitle">预览卡券</div>
                            <div class="cradItem" :style="{ background: formModel.background}">
                                <div class="cradItemTitle">
                                    {{formModel.productname?formModel.productname:CustomerName_Show}}
                                </div>
                                <div class="cradItemMain">
                                    <div class="imgs">
                                        <img v-if="fileList.length" :src="fileList[0].response">
                                        <div v-else class="img"></div>
                                    </div>
                                    <div class="info">
                                        <div class="infoTitle">
                                            {{formModel.name?formModel.name:'优惠劵名称'}}
                                        </div>
                                        <div class="infoPrice">
                                            <span>{{formModel.cash?formModel.cash:'0'}}</span><div>元</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cradItemFooter">
                                    <div class="footerDate">
                                        有效期:{{formModel.effectday?formModel.effectday:'0'}}天
                                    </div>
                                    <div class="footerPrice">
                                        ¥ {{formModel.price?formModel.price:'0'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-layout-content>

                    <a-layout-footer style="height:64px;padding:0 0 16px 0 ;background-color:#fff;">

                        <a-row>
                            <a-col :span="8" align="middle">
                                <a-button  size="large" type="primary" @click="save('ruleForm')">保存</a-button>
                                <a-button size="large" style="margin-left:16px;" centered Vertically @click="cancle">取消</a-button>
                            </a-col>
                        </a-row>

                    </a-layout-footer>
                </a-layout>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<style>
    .bm-view {
        width: 100%;
        height: 700px;
    }
</style>

<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import Rich from "@/components/RichEditor.vue";
    import { message } from 'ant-design-vue'
    import util from "@/Plugin/util.js";
    import { ColorPicker } from "element-ui"
    import 'element-ui/lib/theme-chalk/index.css';
    import Account from "@/Plugin/Account.js";
    export default {
        name: "SiteDetail",
        components: {
            Rich,
            ColorPicker
        },
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 21 },
                    },
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                endOpen: false,
                fileList: [],
                formModel: {
                    id: this.$route.query.ID,
                    activityID: this.$route.query.ActivityID,
                    prizetype: this.$route.query.PrizeType,
                    name: '',
                    count: 0,
                    cash: 0,
                    beginTime: "",//开始时间
                    endTime: "",//结束时间
                    pic: "",
                    description: "",
                    effectday: "",
                    price: "",
                    getmodel: "",
                    tmplid: "",
                    productid: "",
                    background: "#D5261A",
                    productname: "",
                    CustomerName_Show: "",
                    Status:"",


                },
                rules: {
                    name: { validator: this.validateUserName, trigger: 'change', required: true },
                    linkman: { validator: this.validateUserName, trigger: 'change', required: true },
                    address: { validator: this.validateUserName, trigger: 'change', required: true },
                    beginTime: { validator: this.validateUserName, message: "请输入开始时间", required: true, trigger: "change" },
                    endTime: { validator: this.validateUserName, message: "请输入结束时间", required: true, trigger: "change" }
                }

            };
        },
        methods: {

            disabledStartDate(startValue) {
                const endValue = this.formModel.endTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.formModel.beginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                }
                self.fileList = info.fileList.sort();
            },
            removeImg: function (data) {
                console.log(data);
            },
            validateUserName: function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('该字段不能为空'));
                } else {
                    callback();
                }
            },
            validatelinkphone: function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('该字段不能为空'));
                } else {
                    if (util.GetPhone(value) === '') {
                        callback(new Error('请输入正确的联系电话'));
                    } else {
                        callback();
                    }
                }
            },
            getData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetAwardDetail",
                    data: {
                        id: self.formModel.id
                    },
                    OnSuccess: function (data) {
                        console.log("data:aaaa");
                        self.formModel.activityID = data.data.ActivityID;
                        self.formModel.prizetype = data.data.PrizeType;
                        self.formModel.name = data.data.Name;
                        self.formModel.count = data.data.Count;
                        self.formModel.cash = data.data.Cash;
                        self.formModel.beginTime = util.TimeStamptoDateTime(data.data.BeginTime, 'yyyy-MM-dd hh:mm:ss');//  data.data.BeginTime;
                        self.formModel.endTime = util.TimeStamptoDateTime(data.data.EndTime, 'yyyy-MM-dd hh:mm:ss');// data.data.EndTime;
                        self.formModel.description = data.data.Extend.Description;
                        self.formModel.effectday = data.data.EffectDay;
                        self.formModel.price = data.data.Price;
                        self.formModel.getmodel = data.data.GetModel;
                        self.formModel.tmplid = data.data.TmplID;
                        self.formModel.productid = data.data.ProductID;
                        self.formModel.background = data.data.BackgroundColor;
                        self.formModel.productname = data.data.ProductName;
                        self.formModel.Status = data.data.Status;
                        console.log("formmodel status:;;", self.formModel.Status);
                        //var Img = data.data.Template.ViewImg;
                        var Img = data.data.Extend.Pic;
                        if (Img != "" && Img != null) {
                            self.fileList = [
                                {
                                    name: Img,
                                    response: Img,
                                    status: "done",
                                    uid: "0",
                                    url: Img,
                                },
                            ];
                        } else {
                            self.fileList = [];
                        }
                    }
                };
                http.Post(op);
            },
            save: function (formName) {
                var self = this;
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        if (self.checkImage()) {
                            var op = {
                                url: "/MarketingModule/M2Activity/EditM2ActivityAwardPoolNew",
                                data: {
                                    ID: self.formModel.id,
                                    ActivityID: self.formModel.activityID,
                                    Name: self.formModel.name,
                                    PrizeType: self.formModel.prizetype,
                                    BeginTime: self.formModel.beginTime,
                                    EndTime: self.formModel.endTime,
                                    Count: self.formModel.count,
                                    Cash: self.formModel.cash,
                                    Pic: self.fileList.length > 0 ? self.fileList[0].response : "",
                                    Description: self.formModel.description,
                                    EffectDay: self.formModel.effectday,
                                    Price: self.formModel.price,
                                    GetModel: self.formModel.getmodel,
                                    TmplID: self.formModel.tmplid,
                                    ProductID: self.formModel.productid,
                                    BackgroundColor: self.formModel.background,
                                    //CommissionDetails: [{ "Mode": 0,"Proportion":33.33,"Orders":1 }, { "Mode": 0,"Proportion":33.33,"Orders":2 }, { "Mode": 0,"Proportion":33.33,"Orders":3 }],
                                },
                                OnSuccess: function (data) {
                                    if (data.data) {
                                        var display = "新增成功";
                                        if (self.formModel.id != null && self.formModel.id != -1) {
                                            display = "编辑成功";
                                        }
                                        message.success(
                                            display,
                                            1.5,
                                            function () {
                                                self.$router.push({
                                                    name: "Material_PrizeList"
                                                })
                                            }
                                        );
                                    }
                                }
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                });
            },
            cancle() {
                var self = this;
                self.$router.push({
                    name: "Material_PrizeList"
                })
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.fileList.length == 0 && self.formModel.prizetype == 2) {
                    rt = false;
                    self.$message.error("请上传图片！");
                }
                return rt;
            },
        },
        beforeCreate: function () {
            //生命周期，创建前
            var self = this;
            Account.GetCurrentUserInfo(function (CurrentUserInfo) {
                self.CustomerName_Show = CurrentUserInfo.CustomerName;
                self.SoftVersion = CurrentUserInfo.SoftVersion;
            });
        },
        mounted() {
            var self = this;
            Account.GetCurrentUserInfo(function (CurrentUserInfo) {
                self.CustomerName_Show = CurrentUserInfo.CustomerName;
                self.SoftVersion = CurrentUserInfo.SoftVersion;
            });
            if (self.formModel.id != null && self.formModel.id != -1) {
                self.getData();
            }
            
        }
    };
</script>
<style scoped>
    .cardMain {
        background-color: #fff;
        padding: 20px 20px 16px;
        width: 1400px;
        display: grid;
        grid-template-columns: 70% 30%;
    }

    .cradView {
        background: #ebedf6;
        padding: 20px;
        position: relative;
    }

    .cradTitle {
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .cradItem {
        padding: 15px 20px;
        border-radius: 15px;
        color: #fff;
        cursor: pointer;
    }

    .cradItemTitle {
        background: rgba(0, 0, 0, .1);
        text-align: center;
        margin: 0 auto;
        padding: 5px 20px;
        width: 50%;
        border-radius: 20px;
    }

    .cradItem .cradItemMain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        padding: 0 10px;
    }

        .cradItem .cradItemMain .imgs {
            width: 80px;
            height: 80px;
            max-width: 80px;
            max-height: 80px;
        }

        .cradItem .cradItemMain .info {
            font-size: 16px;
            font-weight: bold;
            margin-left: 20px;
            width: 70%;
        }

            .cradItem .cradItemMain .info .infoTitle {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .cradItem .cradItemMain .info .infoPrice {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

                .cradItem .cradItemMain .info .infoPrice > span {
                    margin-right: 10px;
                    font-size: 45px;
                    letter-spacing: 5px;
                    font-weight: 100;
                }

    .cradItem .cradItemFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .cradItem .cradItemFooter .footerPrice {
            font-size: 22px;
            font-weight: 400;
        }

    .imgs .img {
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 10px;
    }

    .imgs img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
</style>



